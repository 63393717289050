/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import {
  Button,
  Go,
  Image,
  Layout,
  ProductBuilder,
  SEO,
  SwiperCarousel,
  Accordion,
  Tag,
  StructuredData
} from "~components";

import { MEDIA_QUERIES } from "~utils/helpers";

const Banner = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${MEDIA_QUERIES.desktop} {
    flex-direction: row;
  }
`;

const Gallery = styled.section`
  width: 50vw;
`;

const Sidebar = styled.section`
  width: 100%;
  min-height: calc(100vh - 3rem);
  display: flex;
  padding: 3rem 1rem;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;

  ${MEDIA_QUERIES.desktop} {
    width: 50vw;
    min-height: calc(100vh - 4rem);
    height: calc(100vh - 4rem);
    position: sticky;
    top: 4rem;
    right: 0;
    padding: 5.5rem 1rem;
  }
`;

/** ============================================================================
 * @component
 * Core template for static site paths.
 */
const Product = ({ data, location }) => {
  const { isDesktop } = useBreakpoint();

  const cms = data.sanityProduct;

  const {
    pagebuilder: { sections }
  } = cms;

  const seoProps = {
    customTitle: cms?.seoTitle ? cms.seoTitle : cms.name,
    customDescription: cms?.seoDescription,
    customKeywords: cms?.seoKeywords,
    path: location.pathname
  };

  return (
    <>
      <SEO {...seoProps} />
      <StructuredData
        sections={sections}
        {...seoProps}
        pageType="product"
        collection={cms.collection}
      />

      <Layout>
        <Banner
          css={css`
            background: ${cms?.primaryColour?.hex || `var(--color-white)`};
          `}
        >
          {isDesktop ? (
            <Gallery>
              {cms?.gallery?.map((image) => (
                <Image image={image} />
              ))}
            </Gallery>
          ) : (
            <SwiperCarousel
              css={css`
                width: 100%;
              `}
              slides={cms?.gallery?.map((image) => (
                <Image image={image} />
              ))}
              options={{ pagination: { clickable: true } }}
            />
          )}

          <Sidebar>
            <Go
              to={`/collections/${cms?.collection?.slug?.current}`}
              _css={css`
                ${MEDIA_QUERIES.desktop} {
                  padding: 0 6.25vw;
                }
              `}
            >
              <h3
                className="b1"
                css={css`
                  margin-bottom: 0.75rem;
                `}
              >
                {cms?.collection?.name}
              </h3>
            </Go>

            <h1
              className="d3"
              css={css`
                margin-bottom: 3rem;
                color: ${cms?.secondaryColour?.hex || `var(--color-black)`};
                text-transform: uppercase;
                text-align: center;

                ${MEDIA_QUERIES.desktop} {
                  padding: 0 6.25vw;
                }
              `}
            >
              {cms.name}
            </h1>

            <Go
              to="/stockists/"
              _css={css`
                width: 100%;

                ${MEDIA_QUERIES.desktop} {
                  padding: 0 12.5vw;
                }
              `}
            >
              <Button text="Find Stockists" />
            </Go>

            <p
              className="b1"
              css={css`
                margin: 3rem 0 1.5rem;
                white-space: pre-line;

                ${MEDIA_QUERIES.desktop} {
                  padding: 0 12.5vw;
                }
              `}
            >
              {cms.description}
            </p>

            {(cms.isNew || cms.isVegan) && (
              <div
                css={css`
                  width: 100%;
                  display: flex;
                  gap: 0.25rem;
                  margin-bottom: 1.5rem;

                  ${MEDIA_QUERIES.desktop} {
                    padding: 0 12.5vw;
                  }
                `}
              >
                {cms.isNew && <Tag text="New" />}

                {cms.isVegan && <Tag text="Vegan" />}
              </div>
            )}

            <div
              css={css`
                width: 100%;
                position: relative;

                ${MEDIA_QUERIES.desktop} {
                  padding: 0 12.5vw;
                }
              `}
            >
              <Accordion items={cms?.information} />
            </div>
          </Sidebar>
        </Banner>

        {cms?.pagebuilder?.sections?.[0] && (
          <ProductBuilder pagebuilder={cms.pagebuilder} />
        )}
      </Layout>
    </>
  );
};

export default Product;

export const query = graphql`
  query Product($id: String!) {
    sanityProduct(id: { eq: $id }) {
      name
      description

      collection {
        name
        slug {
          current
        }
      }

      primaryColour {
        hex
        title
      }

      secondaryColour {
        hex
        title
      }

      isNew
      isVegan
      isDiscontinued

      information {
        _key
        heading
        _rawText
      }

      gallery {
        altText
        asset {
          gatsbyImageData(
            width: 1024
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
        mobileImage {
          asset {
            gatsbyImageData(
              width: 720
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }

      seoTitle
      seoDescription
      seoKeywords

      ...ProductBuilderFragment
    }
  }
`;
